import React from 'react'
import { ThemeProvider } from 'styled-components'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import GlobalStyle from '../styles/global'
import theme from '../styles/theme'
import config from '../utils/siteConfig'
import TopNavComponent from './Menu'
import MobileTopNavComponent from './MenuMobile'
import Container from './Container'
// eslint-disable-next-line import/no-named-as-default
import Footer from './FooterDynamic'

import '../fonts/fonts.scss'

const StyledMain = styled.main`
  min-height: 62vh;
`

// eslint-disable-next-line react/prop-types
const Template = ({ children }) => {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className="siteRoot">
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <>
          <div className="siteContent">
            <div id="page-wrap">
              <Container topNav>
                <TopNavComponent key={`topnav-desktop`} links={config.menuLinks} />
                <MobileTopNavComponent key={`topnav-mobile`} links={config.menuLinks} />
              </Container>
              <StyledMain>{children}</StyledMain>
              <Footer />
            </div>
          </div>
        </>
      </ThemeProvider>
      <GlobalStyle />
    </div>
  )
}

export default Template
