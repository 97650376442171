import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components'
import theme from '../styles/theme'

const MenuMobileLinkComponent = props => {
  const [isOpen, setIsOpen] = useState(false)

  const MainLink = styled.div`
    font-size: 20px;
    background: transparent
    display: block;
    a {
      font-family: ${props => props.theme.fonts.title};
      font-weight: 800;
      padding: .5rem .5rem .5rem 0;
      display: block;
      color: ${props => props.theme.colors.secondary} !important;
      border-bottom: 3px solid ${props => props.theme.colors.highlight};
    }
  `

  const SubLinkList = styled.ul`
    background: white;
    opacity: 0.7;
    font-size: 18px;
    margin: 0 0 1.5rem 1rem;
    li {
      border-bottom: 2px solid ${props => props.theme.colors.secondary};
      position: relative;
      padding: 0 0.5rem 0 0.8rem;
      display: block;
      a {
        border: none;
        display: inline-block;
        position: relative;
        color: ${props => props.theme.colors.secondary};
      }
    }
  `

  const subLinksMapped = props.data.subLinks.map((el, i) => (
    <li key={`sublink-${i}`}>
      <Link activeClassName="active" to={el.link}>
        {el.title}
      </Link>
    </li>
  ))

  return (
    <div>
      <ThemeProvider theme={theme}>
        {!props.data.isDropdown ? (
          <MainLink>
            <Link activeClassName="active" to={props.data.link}>
              {props.data.title}
            </Link>
          </MainLink>
        ) : (
          <MainLink>
            <Link
              activeClassName="active"
              to={props.data.link}
              style={{ opacity: isOpen ? 0 : 1 }}
            >
              {props.data.title}
            </Link>
            <SubLinkList className={isOpen ? '' : 'invisible'}>
              {subLinksMapped}
            </SubLinkList>
          </MainLink>
        )}
      </ThemeProvider>
    </div>
  )
}

export default MenuMobileLinkComponent
