import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import styled from 'styled-components'
import Container from './Container'
import FooterBody from './FooterBody'
import { Link } from 'gatsby'
import { particleConfig } from '../utils/particles'

import 'semantic-ui-css/components/icon.css'
import 'semantic-ui-css/components/button.css'

import loadable from '@loadable/component'
const ParticleComponent = loadable(() => import('react-tsparticles'))

const Wrapper = styled.footer`
  display: flex;
  position: relative;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  background: ${props => props.theme.colors.secondary};
`

const List = styled.ul`
  max-width: ${props => props.theme.sizes.maxWidth};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  // border-top: 1px solid ${props => props.theme.colors.secondary};
  padding: 0;
  margin: 0 auto;
`

const StyledButton = styled.button`
  &.circular.ui.button {
    background: ${props => props.theme.colors.highlight};
  }
`

const Item = styled.li`
  display: inline-block;
  z-index: 10;
  padding: 0.25em 0;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  h4 {
    font-size: 1.1em;
    font-family: ${props => props.theme.fonts.title};
    font-weight: 800;
    margin: 1rem auto;
    color: ${props => props.theme.colors.highlight};
  }
  h6 {
    margin-top: 1rem;
  }
  p {
    display: flex;
    font-family: ${props => props.theme.fonts.text};
    // margin-bottom: 1rem;
    line-height: 1.3;
    font-weight: normal;
    color: ${props => props.theme.colors.inverted};
  }
  a,
  a span {
    font-family: ${props => props.theme.fonts.text};
    font-weight: normal;
    transition: all 0.2s;
    display: block;
    line-height: 1.3;
    border: none;
    margin-bottom: 0;
    &.inline {
      font-weight: 700;
      display: inline;
    }
    color: ${props => props.theme.colors.inverted};
    &:hover {
      color: ${props => props.theme.colors.highlight} !important;
    }
  }
`

export default function Footer() {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          contentfulFooter {
            id
            columns {
              id
              text {
                json
              }
            }
          }
        }
      `}
      render={data => {
        // console.log(data)

        const mappedColumns = data.contentfulFooter.columns.map((el, i) => (
          <Item key={`footer-el-${el.id}`}>
            <FooterBody body={el.text} />
          </Item>
        ))

        return (
          <Wrapper>
            {typeof window !== 'undefined' &&
						<ParticleComponent
							width="100%"
							id={'particle-wrapper-footer'}
							params={particleConfig}
							style={{
								opacity: 0.6,
								zIndex: 1,
								backgroundColor: 'transparent',
								position: 'absolute', 
								width: '100%',
								height: 'auto',
								top: 0
							}}
						/>
						}
            <Container>
              <List>{mappedColumns}</List>
            </Container>
          </Wrapper>
        )
      }}
    />
  )
}
