import React from 'react'
import styled from 'styled-components'
import '../styles/gradients.scss'

const Container = props => {
  const Wrapper = styled.section`
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: ${props => props.theme.sizes.maxWidth};
    padding: 2rem 0;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      padding: 2rem;
    }
    &.relaxed {
      position: relative;
      padding: 4rem 0;
      @media screen and (max-width: ${props => props.theme.responsive.large}) {
        padding: 1rem 2rem 2rem 2rem;
      }
    }
    @media screen and (max-width: ${props => props.theme.responsive.small}) {
      padding: 1.5rem 1.5rem;
    }
    display: block;
    flex-grow: 1;
    &.no-padding-top {
      padding-top: 0;
    }
    h3 {
      font-size: 1.2em;
      line-height: 1.25;
      color: ${props => props.theme.colors.highlight};
    }
    &.top-nav {
      padding: 2.5rem 0 1rem 0;
      @media screen and (max-width: 800px) {
        padding: 1rem;
      }
    }
    &.no-padding {
      padding: 0;
    }
  `

  return (
    <Wrapper
      style={props.style}
      className={`${props.topNav ? 'top-nav' : ''} 
      ${props.second ? 'no-padding-top' : ''}
      ${props.relaxed ? 'relaxed' : ''}
      ${props.noPadding ? 'no-padding' : ''}
      ${props.noPaddingTop ? 'no-padding-top' : ''}
      `}
    >
      {props.children}
    </Wrapper>
  )
}

export default Container
