/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import styled from 'styled-components'
import { Link, StaticQuery, graphql } from 'gatsby'

const JobButtonComponent = props => {
  const StyledButtonContainer = styled.div`
    position: relative;
    // display: none;
    right: 0;
    top: 0;
    z-index: 555;
    a {
      position: absolute;
      right: -7rem;
      top: 4.6rem;
      bottom: 0;
      @media screen and (max-width: 1150px) {
        display: none;
        right: 0;
        position: relative;
        top: 8rem;
      }
      box-shadow: 0px 8px 16px 0px rgba(255, 255, 255, 0.2);
      background: ${props => props.theme.colors.highlight};
      border-radius: 50%;
      transform: translate(-50%, -50%);
      line-height: 5rem;
      vertical-align: middle;
      text-align: center;
      color: ${props => props.theme.colors.inverted};
      font-family: ${props => props.theme.fonts.title};
      font-size: 1.2em;
      width: 5rem;
      height: 5rem;
      &:hover {
        background: ${props => props.theme.colors.secondary};
      }
    }
  `

  return (
    <StaticQuery
      query={graphql`
        query {
          allContentfulJob {
            totalCount
          }
        }
      `}
      render={data => (
        <StyledButtonContainer
          style={{
            display: data.allContentfulJob.totalCount > 0 ? 'block' : 'none',
          }}
        >
          <Link to="/jobs">Jobs</Link>
        </StyledButtonContainer>
      )}
    />
  )
}
export default JobButtonComponent
