import theme from '../styles/theme'

// eslint-disable-next-line import/prefer-default-export
export const particleConfig = {
  particles: {
    number: {
      value: 120,
      density: {
        enable: true,
        value_area: 1362.9002517356944,
      },
      line_linked: {
        enable: true,
        distance: 272.58005034713887,
        color: theme.colors.inverted,
        opacity: 0.42490419612936353,
        width: 1.2827296486924182,
      },
    },
    color: {
      value: theme.colors.highlight,
    },
    shape: {
      type: '',
      stroke: {
        width: 0,
        color: theme.colors.inverted,
      },
      polygon: {
        nb_sides: 1,
      },
      image: {
        src: 'http://marcfehr.ch/vermflex/favicon-green-25.png',
        width: 50,
        height: 50,
      },
    },
    opacity: {
      value: 1,
      random: true,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false,
      },
    },
    size: {
      value: 60.1279522824571,
      random: true,
      anim: {
        enable: true,
        speed: 3,
        size_min: 48.72463273808071,
        sync: false,
      },
    },
    move: {
      enable: true,
      speed: 1,
      direction: 'none',
      random: true,
      straight: false,
      out_mode: 'out',
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1122.388442605866,
      },
    },
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onhover: {
        enable: false,
        mode: 'bubble',
      },
      onclick: {
        enable: true,
        mode: 'push',
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 400,
        line_linked: {
          opacity: 1,
        },
      },
      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 8,
        speed: 3,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
      push: {
        particles_nb: 2,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  retina_detect: true,
  fullScreen: {
    enable: false,
  },
}
