/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import MenuLinkComponent from './MenuLink'
import JobsButtonComponent from './JobsButton'
import Logo from './Logo'

const TopNavComponent = props => {
  const mappedLinks = props.links.map((el,i) =>(
    <MenuLinkComponent key={`menu-link-wrapper-desktop-${i}`} data={el} />
  ))

  const StyledNav = styled.nav`
    display: none;
    // eslint-disable-next-line react/prop-types
    @media screen and (min-width: ${props => props.theme.responsive.medium}) {
      display: flex;
    }
    flex-direction: row;
    align-items: stretch;
    flex-align: flex-end; 
    justify-content: space-around';
    margin-bottom: 1rem;
  `

  return (
    <StyledNav>
      <Link to="/">
        <Logo key="logo-desktop" />
      </Link>
      {mappedLinks}
      <JobsButtonComponent />
    </StyledNav>
  )
}

export default TopNavComponent
