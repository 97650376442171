/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import Container from './Container'

require('prismjs/themes/prism.css')

const Body = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};

  h1,
  h2 {
    font-size: 2.2em;
  }

  h1,
  h2,
  h3 {
    font-weight: 800;
    line-height: 1.25;
    margin: 1rem 0;
    font-family: ${props => props.theme.fonts.title};
  }

  h1 {
    display: table;
    margin-bottom: 0;
    color: ${props => props.theme.colors.highlight};
    @media screen and (max-width: 500px) {
      margin: 1rem 0;
      color: ${props => props.theme.colors.highlight};
      background: transparent;
      font-size: 1.4em;
      line-height: 1.25;
      padding: 0;
    }
  }
  h2 {
    color: ${props => props.theme.colors.secondary};
    margin-bottom: 1.5rem;
    margin-top: -0.25rem;
    @media screen and (max-width: 500px) {
      font-size: 1.2em;
      line-height: 1.4;
    }
  }
  h3 {
    font-size: 1em;
  }

  p {
    font-weight: 400;
    line-height: 1.6;
    margin: 0 0 2em 0;
    a {
      border-bottom: 2px solid ${props => props.theme.colors.highlight};
      font-weight: 700;
      &:hover {
        background: ${props => props.theme.colors.highlight};
        color: ${props => props.theme.colors.base};
      }
    }
  }

  a {
    transition: 0.2s;
    color: ${props => props.theme.colors.base};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }

  del {
    text-decoration: line-through;
  }
  strong {
    font-weight: 700;
  }
  em {
    font-style: italic;
  }

  ul,
  ol {
    margin: 0 0 2em 0;
  }

  ul {
    li {
      list-style: disc;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 0;
      }
    }
  }

  ol {
    li {
      list-style: decimal;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 0;
      }
    }
  }

  hr {
    border-style: solid;
    border-color: ${props => props.theme.colors.secondary};
    margin: 0 0 2em 0;
  }

  blockquote {
    font-style: italic;
    border-left: 4px solid ${props => props.theme.colors.secondary};
    padding: 0 0 0 0.5em;
  }

  pre {
    margin: 0 0 2em 0;
    border-radius: 2px;
    background: ${props => props.theme.colors.secondary} !important;
    span {
      background: inherit !important;
    }
  }
  @media screen and (max-width: 500px) {
    .ui.grid > .stackable.stackable.row > .column,
    .ui.stackable.grid > .column.grid > .column,
    .ui.stackable.grid > .column.row > .column,
    .ui.stackable.grid > .column:not(.row),
    .ui.stackable.grid > .row > .column,
    .ui.stackable.grid > .row > .wide.column,
    .ui.stackable.grid > .wide.column {
      padding: 0 !important;
    }
  }
`

const TextContainer = props => {
  return (
    <Container second noPadding>
      <Body style={props.style}>{props.children}</Body>
    </Container>
  )
}

export default TextContainer
