import React from 'react'
import styled from 'styled-components'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

require('prismjs/themes/prism.css')

const Body = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  h1,
  h2,
  h3,
  h4 {
    font-weight: 800;
    font-family: ${props => props.theme.fonts.text};
    line-height: 1.25;
    margin: 1rem 0 2rem 0;
  }

  h1 {
    font-size: 1.5em;
    display: table;
    background: ${props => props.theme.colors.highlight};
    color: ${props => props.theme.colors.base};
    padding: 0.3rem;
    border-radius: 2px;
    @media screen and (max-width: 500px) {
      color: ${props => props.theme.colors.highlight};
      background: transparent;
      font-size: 1.25em;
      padding: 0;
    }
  }

  h2 {
    font-size: 1.25em;
    display: table;
    background: ${props => props.theme.colors.tertiary};
    color: ${props => props.theme.colors.base};
    border-radius: 2px;
    @media screen and (max-width: 500px) {
      font-size: 1em;
    }
  }

  h3 {
    margin: 2rem 0 1rem 0;
    @media screen and (max-width: 500px) {
      font-size: 1.4em !important;
    }
    &:first-child {
      margin: 1rem 0;
    }
  }

  h4 {
    font-size: 1.25em;
    display: block;
    color: ${props => props.theme.colors.secondary};
    font-weight: 800;
    margin: 1rem 0 1rem 0;
  }

  p, h6 {
    font-weight: 400;
    line-height: 1.40;
    hyphens: auto;
    font-family: ${props => props.theme.fonts.text};
    color: ${props => props.theme.colors.secondary};
    margin-bottom: 0;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      // color: ${props => props.theme.colors.highlight};
      &:hover {
        color: ${props => props.theme.colors.highlight};
      }
    }
  }

  a {
    hyphens: auto;
    border: none !important;
    transition: 0.2s;
    color: ${props => props.theme.colors.base};
    &:active, &:visited {
      color: ${props => props.theme.colors.inverted}; 
    }
    &:hover {
      color: ${props => props.theme.colors.highlight};
      background: none !important;
    }
  }

  del {
    text-decoration: line-through;
  }
  strong {
    font-weight: 800;
    font-family: 'AllerBold', sans-serif;
  }
  em {
    font-style: italic;
  }

  ul,
  ol {
    margin: 2em 0 2em 0;
  }

  ul {
    // margin-top: 2rem;
    li {
      list-style: disc;
      hyphens: auto;
      list-style-position: inside;
      line-height: 1.25;
      p {
        display: inline;
      }
      &:last-child {
        margin: 0;
      }
    }
  }

  ol {
    li {
      list-style: decimal;
      hyphens: auto;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 0;
      }
    }
  }

  hr {
    border-style: solid;
    border-color: ${props => props.theme.colors.secondary};
    margin: 0 0 2em 0;
  }

  blockquote {
    font-style: italic;
    border-left: 4px solid ${props => props.theme.colors.secondary};
    padding: 0 0 0 0.5em;
  }

  pre {
    margin: 0 0 2em 0;
    border-radius: 2px;
    background: ${props => props.theme.colors.secondary} !important;
    span {
      background: inherit !important;
    }
  }
  
  &.left-aligned {
    margin: 0;
  }
  
  &.grey-text {
    * {
      color: ${props => props.theme.colors.secondary};
    }
  }
`

const ImgWrapper = styled.div`
  color: ${props => props.theme.colors.tertiary};
  text-align: center;
  width: 100%;
  margin: 1.5rem auto;
  overflow-y: hidden;
`

const PageBody = props => {
  const content = props.body.json

  const ImgComponent = ({ src, caption }) => (
    <ImgWrapper>
      <img src={src} style={{ width: '100%' }} />
      <p>{caption}</p>
    </ImgWrapper>
  )

  const options = {
    renderMark: {
      [INLINES.HYPERLINK]: link => (
        <a target={link.target} rel="noopener noreferrer" href={link.href}>
          {content}
        </a>
      ),
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [BLOCKS.HEADING_1]: text => <h1>{text}</h1>,
      [BLOCKS.HEADING_2]: text => <h2>{text}</h2>,
      [BLOCKS.HEADING_3]: text => <h3>{text}</h3>,
      [BLOCKS.HEADING_4]: text => <h4>{text}</h4>,
      [BLOCKS.HEADING_5]: text => <h5>{text}</h5>,
      [BLOCKS.HEADING_6]: text => <h6>{text}</h6>,
      [BLOCKS.LIST_ITEM]: text => <li>{text}&nbsp;</li>,
      [BLOCKS.UL_LIST]: text => <ul>{text}</ul>,
      [BLOCKS.OL_LIST]: text => <ol>{text}</ol>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.EMBEDDED_ASSET]: node => {
        // console.log(node)
        const { file, description } = node.data.target.fields
        // console.log(file["en-US"].url)
        return (
          <ImgComponent
            src={file['de-CH'].url}
            caption={description['de-CH']}
          />
        )
      },
    },
  }

  return (
    <Body className={`${props.className}`}>
      {documentToReactComponents(props.body.json, options)}
    </Body>
  )
}

export default PageBody
