/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components'
import theme from '../styles/theme'

const MenuLinkComponent = props => {
  const [isOpen, setIsOpen] = useState(false)

  const MainLink = styled.div`
    background: white;
    height: 2.6rem;
    width: auto;
    display: flex;
    align-items: center;
    float: left;
    align-self: center;
    border-bottom: 3px solid transparent;

    &.mobile-only {
      @media screen and (min-width: 1150px) {
        display: none;
      }
    }
    &.small-screen-only {
      @media screen and (min-width: 1150px) {
        display: none;
      }
    }
    &:hover {
      border-bottom: 3px solid;
      border-bottom-color: ${props => props.theme.colors.highlight};
    }
    a {
      opacity: 0.8;
      font-family: ${props => props.theme.fonts.text};
      font-size: 1.1em;
      @media screen and (max-width: ${props => props.theme.responsive.large}) {
        font-size: 0.9em;
        line-height: 1.1em;
      }
      line-height: 1.1em;
      font-weight: 800;
      color: ${props => props.theme.colors.secondary};
      &:hover {
        opacity: 1;
      }
      &.active {
        opacity: 1;
        color: ${props => props.theme.colors.highlight};
      }
    }
    width: auto;
    text-align: center;
    padding: 0.5rem 2rem 0.5rem 2rem;

    &.is-dropdown {
      padding-top: 0.66rem;
      border: none;
      opacity: 0.8;
      z-index: 999;
      position: relative;
      padding-left: 0;
      padding-right: 0;
      display: inline-block;
      margin-right: -3rem;
      margin-left: -3rem;
    }
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  `

  const SubLinkList = styled.ul`
    background: white;
    text-align: center;
    background: white;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 0.57rem 1rem 0.25rem 1rem;
    margin-top: -2.25rem;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      margin-top: -2rem;
    }
    &.invisible {
      opacity: 0;
    }
    li {
      z-index: 999;
      background: white;
      position: relative;
      display: block;
      padding: 0.5rem;
      border-top: 3px solid;
      font-family: ${props => props.theme.fonts.text};
      font-weight: 800;
      border-top-color: ${props => props.theme.colors.highlight};
      a {
        opacity: 0.8;
        display: block;
        position: relative;
        font-size: 1.1em;
        @media screen and (max-width: ${props =>
            props.theme.responsive.large}) {
          font-size: 0.9em;
          line-height: 1.1em;
        }
        &.active {
          color: ${props => props.theme.colors.highlight};
          opacity: 1;
        }
        color: ${props => props.theme.colors.secondary};
      }
    }
  `

  const subLinksMapped = props.data.subLinks.map((el, i) => (
    // eslint-disable-next-line react/jsx-filename-extension
    <li key={`sublink-desktop-${i}`}>
      <Link
        activeClassName={'active'}
        to={el.link}
        partiallyActive
        key={`desktop-menu-sublink-${i}`}
      >
        {el.title}
      </Link>
    </li>
  ))

  return (
    <div
      style={{
        alignItems: 'flex-end',
        display: 'inline-flex',
        overflow: isOpen ? 'visible' : 'hidden',
      }}
      onMouseLeave={() => setIsOpen(false)}
    >
      <ThemeProvider theme={theme}>
        {!props.data.isDropdown ? (
          <MainLink
            className={`${props.data.mobileOnly ? 'mobile-only' : ''} ${
              props.data.smallScreenOnly ? 'small-screen-only' : ''
            }`}
          >
            <Link partiallyActive activeClassName="active" to={props.data.link}>
              {props.data.title}
            </Link>
          </MainLink>
        ) : (
          <MainLink
            className={`is-dropdown ${
              props.data.mobileOnly ? 'mobile-only' : ''
            }`}
            onMouseEnter={() => setIsOpen(true)}
          >
            <Link
              to={props.data.link}
              style={{ opacity: isOpen ? 0 : 1 }}
              activeClassName="active"
              partiallyActive
            >
              {props.data.title} <i className="small icon caret down" />
            </Link>
            <SubLinkList className={isOpen ? '' : 'invisible'}>
              <li
                style={{ border: 'none' }}
                key={`sublink-${props.data.title}`}
              >
                <Link activeClassName={'active'} to={props.data.link}>
                  {props.data.title} <i className="small icon caret up" />
                </Link>
              </li>
              {subLinksMapped}
            </SubLinkList>
          </MainLink>
        )}
      </ThemeProvider>
    </div>
  )
}

export default MenuLinkComponent
