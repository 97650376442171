const theme = {
  colors: {
    base: '#474A4A', // Black
    secondary: '#474A4A', // Medium Gray
    tertiary: '#828688', // Light Gray
    lightGrey: '#eee',
    highlight: '#C2CF00', // Green
    inverted: '#FFFFFF',
  },
  sizes: {
    maxWidth: '950px',
    maxWidthCentered: '950px',
  },
  responsive: {
    small: '35em',
    smallMedium: '40em',
    medium: '50em',
    large: '70em',
  },
  fonts: {
    title: '"Aller", sans-serif',
    text: '"Aller", sans-serif',
  },
}

export default theme
