import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { slide as BurgerMenu } from 'react-burger-menu'
import MenuMobileLinkComponent from './MenuMobileLink'
import Logo from './Logo'
import '../styles/animations.scss'

const MobileTopNavComponent = props => {
  const mappedLinks = props.links.map((el, i) => (
    <MenuMobileLinkComponent key={`menu-link-mobile-wrapper-${i}`} data={el} />
  ))

  const MobileNav = styled.nav`
    display: none;
    @media screen and (max-width: ${props => props.theme.responsive.medium}) {
      display: block;
    }

    /* Position and sizing of burger button */
    .bm-burger-button {
      position: fixed;
      width: 36px;
      height: 30px;
      right: 1.3rem;
      top: 1.3rem;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
      background: ${props => props.theme.colors.highlight};
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
      height: 3.5rem;
      width: 3.5rem;
    }

    /* Color/shape of close button cross */
    .bm-cross {
      background: #bdc3c7;
    }

    /*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
    .bm-menu-wrap {
      position: fixed;
      max-width: 70%;
      height: 100%;
      top: 0;
    }

    /* General sidebar styles */
    .bm-menu {
      background: ${props => props.theme.colors.inverted};
      padding: 1rem;
      font-size: 2em;
      a,
      a:visited,
      a:focus {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        color: ${props => props.theme.colors.base};
        font-family: ${props => props.theme.fonts.text};
      }
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
      fill: ${props => props.theme.colors.base};
    }

    /* Wrapper for item list */
    .bm-item-list {
      color: ${props => props.theme.colors.secondary};
      padding: 1.2rem 0 0 0;
    }

    /* Individual item */
    .bm-item {
      display: inline-block;
      outline: none;
    }

    /* Styling of overlay */
    .bm-overlay {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.7);
    }
  `

  return (
    <MobileNav>
      <Link to="/">
        <Logo inline />
      </Link>
      {typeof window !== 'undefined' && (
        <BurgerMenu pageWrapId="page-wrap" right>
          <Link to="/">
            <Logo />
          </Link>
          <div style={{ marginTop: '1.5rem' }}>{mappedLinks}</div>
        </BurgerMenu>
      )}
    </MobileNav>
  )
}

export default MobileTopNavComponent
