module.exports = {
  siteTitle: 'Vermflex GmbH',
  siteTitleAlt: 'Vermflex', // This allows an alternative site title for SEO schema.
  publisher: 'Marc Fehr Media', // Organization name used for SEO schema
  siteDescription:
    'Vermflex GmbH in St. Gallen – Vermessung nach Mass.',
  siteUrl: 'https://www.vermflex.ch', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  postsPerHomePage: 7, // Number of posts shown on the 1st page of of the index.js template (home page)
  postsPerPage: 6, // Number of posts shown on paginated pages
  author: 'Vermflex GmbH', // Author for RSS author segment and SEO schema
  authorUrl: 'https://www.marcfehr.ch', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: '@vermflex', // Change for Twitter Cards
  shortTitle: 'Vermflex', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/share.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 900, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: '/logo.svg', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#e9e9e9', // Used for Offline Manifest
  themeColor: '#121212', // Used for Offline Manifest
  copyright: 'Copyright © ' + new Date().getFullYear() + ' Vermflex GmbH', // Copyright string for the RSS feed
  menuLinks: [
    {
      link: '/handwerk',
      title: 'Handwerk',
      isDropdown: true,
      subLinks: [
        {
          link: '/handwerk/drohnen',
          title: 'Drohnen-vermessung',
        },
        {
          link: '/handwerk/digitale-baustelle',
          title: 'Digitale Baustelle',
        },
        {
          link: '/handwerk/monitoring',
          title: 'Monitoring',
        },
        {
          link: '/handwerk/bim',
          title: 'BIM',
        },
      ]
    },
    {
      link: '/mannschaft',
      title: 'Mannschaft',
      isDropdown: false,
      subLinks: []
    },
    {
      link: '/kunden',
      title: 'Kunden',
      isDropdown: false,
      subLinks: []
    },
    {
      link: '/preise',
      title: 'Preise',
      isDropdown: false,
      subLinks: []
    },
    {
      link: '/jobs',
      title: 'Jobs',
      isDropdown: false,
      subLinks: [],
      smallScreenOnly: true
    },
    {
      link: '/kontakt',
      title: 'Kontakt',
      isDropdown: false,
      subLinks: []
    },
  ]
}
