import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import svgLogo from '../images/logo.svg'

const StyledDiv = styled.div`
  width: 250px;
  height: 40px;

  img {
    width: 100%;
    max-width: 209px;
  }
`

const LogoComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulAsset(id: {}, file: {fileName: {eq: "logo.svg"}}) {
        id
        file {
          fileName
        }
        fluid {
          base64
          srcWebp
          srcSetWebp
          srcSet
          src
          sizes
          aspectRatio
        }
      }
    }
  `)

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <StyledDiv>
      <img src={svgLogo} />
    </StyledDiv>
  )
}

export default LogoComponent
